import { loadLinks } from "../../utils/util";
import { luckysheetPrint } from "./print";
import Store from "../../store";

const dependLinks = [
  './expendPlugins/print/print.css',
];

// Initialize the chart component
function print(options, config, isDemo) {
    const { showtoolbar, showtoolbarConfig } = options
    if (luckysheetPrint && showtoolbar && showtoolbarConfig.print !== false) {
        Store.luckysheetPrint = luckysheetPrint;
        if (isDemo) {
          loadLinks(dependLinks);
        }
    }
}

export { print };
