import configsetting from "../../controllers/luckysheetConfigsetting.js";
import Store from "../../store";
import getLocale from "../../locale/locale";
import { replaceHtml } from "../../utils/util";
import { modelHTML } from "../../controllers/constant";
import { luckysheetDrawMain } from "../../global/draw";
import { luckysheetdefaultstyle } from "../../controllers/constant";
import {
  getAllSheets,
  hideGridLines,
  setCellValue,
  setCopyRangeValue,
  showGridLines,
  toJson
} from "../../global/api";
import { getSheetIndex } from "../../methods/get";
import luckysheetscrollevent from "../../global/scroll";
import JsPDF from 'jspdf';
import dayjs from "dayjs";
import {luckysheet} from "../../core";

export function printInitial() {
  const printDom = configsetting['container'];
  $("#" + printDom)['find']('.luckysheet-print-viewBtn')["click"](function () {
    switchViewBtn($(this));
  });
}
export const luckysheetPrint = {
  selectArea: "0",
  direction: "1",
  size: "1",
  sizeList: [[29.7, 42], [21, 29.7], [14.8, 21], [25, 35.3], [17.6, 25], [21.6, 27.9], [27.9, 43.2], [21.6, 35.6], [18.4, 26.7]],
  // padding: [0, 0, 0, 0],
  padding: [0.5, 0.5, 0.5, 0.5],
  saveRange: null,
  canvasList: [],
  jsPdf: null,
  // 初始化 luckysheet_print_config
  initPrintConfig: function () {
    if (Store.luckysheet_print_config == null) {
      Store.luckysheet_print_config = {
        padding: [0.5, 0.5, 0.5, 0.5],
        selectArea: '0',
        size: '1',
        direction: '1'
      }
      const file = Store.luckysheetfile[getSheetIndex(Store.currentSheetIndex)];
      file.luckysheet_print_config = Store.luckysheet_print_config;
    }
  },
  createDialog: function () {
    this.initPrintConfig();
    const { padding, selectArea = "0", size = "1", direction = "1" } = Store.luckysheet_print_config;
    this.padding = padding;
    this.selectArea = selectArea;
    this.direction = direction;
    this.size = size;
    $("#luckysheet-modal-dialog-mask").hide();
    $('#luckysheet-print').remove();
    const currentLocale = getLocale();
    const _printLocale = currentLocale.print;
    const _buttonLocale = currentLocale.button;
    const selected = "selected=selected";
    const typeNum = "type='number'";
    const _content = '<div class="luckysheet-print-content">' +
          '<div class="luckysheet-print-title luckysheet-print-padding"><span>' + _printLocale['padding'] + '</span>' +
          '<div class="luckysheet-print-padding-box">' +
          '<div class="luckysheet-print-padding-box-item"><span>上：</span><input id="padding0" value='+ this.padding[0] + ' ' + typeNum + '/><span>cm</span></div>' +
          '<div class="luckysheet-print-padding-box-item"><span>下：</span><input id="padding2" value='+ this.padding[2] + ' ' + typeNum + '/><span>cm</span></div>' +
          '<div class="luckysheet-print-padding-box-item"><span>左：</span><input id="padding3" value='+ this.padding[3] + ' ' + typeNum + '/><span>cm</span></div>' +
          '<div class="luckysheet-print-padding-box-item"><span>右：</span><input id="padding1" value='+ this.padding[1] + ' ' + typeNum + '/><span>cm</span></div>' +
          '</div>' +
          '</div>' +
          '<div class="luckysheet-print-title"><span>' + _printLocale["size"] + '</span>' +
          '<select class="luckysheet-print-size">' +
          `<option value="0" ${this.size == 0 ? selected : ""}>A3(29.7cm×42.0cm)</option>` +
          `<option value="1" ${this.size == 1 ? selected : ""}>A4(21.0cm×29.7cm)</option>` +
          `<option value="2" ${this.size == 2 ? selected : ""}>A5(14.8cm×21.0cm)</option>` +
          // `<option value="3" ${this.size == 3 ? "selected=selected" : ""}>B4(25.0cm×35.3cm)</option>` +
          // `<option value="4" ${this.size == 4 ? "selected=selected" : ""}>B5(17.6cm×25.0cm)</option>` +
          // `<option value="5" ${this.size == 5 ? "selected=selected" : ""}>${_printLocale['letter']}(21.6cm×27.9cm)</option>` +
          // `<option value="6" ${this.size == 6 ? "selected=selected" : ""}>${_printLocale['paper']}(27.9cm×43.2cm)</option>` +
          // `<option value="7" ${this.size == 7 ? "selected=selected" : ""}>${_printLocale['law']}(21.6cm×35.6cm)</option>` +
          // `<option value="8" ${this.size == 8 ? "selected=selected" : ""}>${_printLocale['admin']}(18.4cm×26.7cm)</option>` +
          '</select>' +
        '</div>' +
        '<div class="luckysheet-print-title"><span>' + _printLocale['direction'] + '</span>' +
        '<div class="luckysheet-print-radio">' +
        '<div>' +
        `<input value="0" name="print" type="radio" id="horizontal" ${this.direction == 0 ? "checked" : ""}/>` +
        '<label for="horizontal">' + _printLocale['horizontal'] + '</label>' +
        '</div>' +
        '<div>' +
        `<input value="1" name="print" type="radio" id="vertical" ${this.direction == 1 ? "checked" : ""}/>` +
        '<label for="vertical">' + _printLocale['vertical'] + '</label>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body')['append'](replaceHtml(modelHTML, {
      id: 'luckysheet-print',
      addclass: 'luckysheet-print',
      title: _printLocale.title,
      content: _content,
      botton: "<button class='btn btn-default luckysheet-model-close-btn'>" + _buttonLocale["close"] + '</button><button class="btn btn-default luckysheet-model-confirm-btn">' + _buttonLocale["confirm"] + '</button>',
      style: "z-index:100003",
      close: _buttonLocale['close']
    }));
    const printDialogContent = $('#luckysheet-print').find('.luckysheet-modal-dialog-content').css('min-width', 400).end();
    const outerHeight = printDialogContent.outerHeight();
    const outerWidth = printDialogContent.outerWidth();
    const windowWidth = $(window).width();
    const windowHeight = $(window).height();
    const scrollLeft = $(document).scrollLeft();
    const scrollTop = $(document).scrollTop();
    $('#luckysheet-print').css({
      left: (windowWidth + scrollLeft - outerWidth) / 2,
      top: (windowHeight + scrollTop - outerHeight) / 3
    })['show']();
  },
  init: function () {
    const _this = this;
    // $(document)["off"]('change.printArea')["on"]('change.printArea', '.luckysheet-print-select-area', function (areaEvent) {
    //   _this.selectArea = areaEvent.currentTarget.value;
    // }),
    $(document)['off']('change.printSize')["on"]('change.printSize', ".luckysheet-print-size", function (pageSizeEvent) {
      _this.size = pageSizeEvent.currentTarget.value;
    }),
    $(document)["off"]("change.printInput")["on"]('change.printInput', '.luckysheet-print-radio input[type="radio"]', function (inputEvent) {
      _this.direction = inputEvent.currentTarget.value;
    }),
    $(document)['off']('click.printConfirm')["on"]("click.printConfirm", '.luckysheet-print .luckysheet-model-confirm-btn', function () {
      for (let i = 0; i < 4; i++) {
        _this.padding[i] = $(`#padding${i}`).val()
      }
      Store.luckysheet_print_config.selectArea = _this.selectArea;
      Store.luckysheet_print_config.size = _this.size;
      Store.luckysheet_print_config.direction = _this.direction;
      const file = Store.luckysheetfile[getSheetIndex(Store.currentSheetIndex)];
      file.luckysheet_print_config = Store.luckysheet_print_config;
      // 重新渲染sheet内容
      luckysheetscrollevent();
      $("#luckysheet-print").hide()
    })
  },
  // 进入打印前置处理
  async onbeforeprint(options) {
    const rowMin = this.saveRange.row[0];
    const rowMax = this.saveRange.row[1];
    const columnMin = this.saveRange.column[0];
    const columnMax = this.saveRange.column[1];
    let _rowMin;
    let _rowMax;
    if (rowMin - 1 < 0) {
      _rowMin = 0;
      _rowMax = Store.visibledatarow[rowMax];
    } else {
      _rowMin = Store.visibledatarow[rowMin - 1];
      _rowMax = Store.visibledatarow[rowMax] - Store.visibledatarow[rowMin - 1];
    }
    let _columnMin;
    let _columnMax;
    if (columnMin - 1 < 0) {
      _columnMin = 0;
      _columnMax = Store.visibledatacolumn[columnMax];
    } else  {
      _columnMin = Store.visibledatacolumn[columnMin - 1];
      _columnMax = Store.visibledatacolumn[columnMax] - Store.visibledatacolumn[columnMin - 1];
    }
    await this.drawCanvas(_columnMax, _rowMax, _columnMin, _rowMin, options)
  },
  // 打印预览文件生成后处理
  async onafterprint(timeout) {
    await new Promise((resolve) => {
      luckysheet.create(JSON.parse(localStorage.getItem('toJson')));
      const timer = setTimeout(() => {
        clearTimeout(timer);
        resolve(true);
      }, timeout * 1000)
    })
  },
  // 打印当前sheet内容
  async printSheet(options = {}) {
    const _this = this;
    localStorage.removeItem('toJson')
    localStorage.setItem('toJson', JSON.stringify(toJson()));
    const { mode = 0, data = [], timeout = 2, scale = 2, eachProps = ['info', 'approve'], itemSuccess, success, ...restOpts } = options;
    if (!success) {
      luckysheet.showLoadingProgress('打印内容生成中...');
    }
    if (mode === 0) {
      await _this.printDataFormat(mode);
      await _this.printBefore();
      await _this.onbeforeprint({ ...restOpts, scale, timeout });
      if (success && typeof success === 'function') {
        success()
      }
    } else {
      for (let index = 0; index < data.length; index++) {
        if (index) {
          await _this.onafterprint(timeout);
        }
        const rowData = data[index];
        await _this.printDataFormat(mode, rowData, eachProps);
        await _this.printBefore();
        const autoPreview = index === data.length - 1;
        await _this.onbeforeprint({ ...restOpts, scale, timeout, autoPreview });
        if (itemSuccess && typeof itemSuccess === 'function') {
          itemSuccess(Math.floor((index + 1) * 100 / data.length));
        }
        if (autoPreview && success && typeof success === 'function') {
          success()
        }
      }
    }
  },
  // 打印数据处理
  async printDataFormat(mode, data, eachProps) {
    const sheetsData = getAllSheets();
    const setCellValueOptions = {
      triggerBeforeUpdate: false,
      triggerUpdated: false
    }
    const cellDataFormat = (cell, curData) => {
      const {r, c, v} = cell
      if (v.field != null) {
        const { type, code, dateFormat = 'YYYY-MM-DD HH:mm:ss' } = v.field
        let m = curData[code + 'Text'] || curData[code];
        if (!([0, ''].includes(m) || m)) m = '';
        if (type == 'date') {
          m = m ? dayjs(new Date(+m)).format(dateFormat) : '';
        }
        if (type == 'multi_select' && m) {
          m = m.join()
        }
        if (type == 'images' && m) {
          m = m.join()
        }
        if (type == 'files' && m) {
          m = m.map((file) => file && file.name).join()
        }
        setCellValue(
            r,
            c,
            { ...cell?.v, m },
            { ...setCellValueOptions }
        )
      }
    }
    // 更新表格内容
    for (let sheetIndex = 0; sheetIndex < sheetsData.length; sheetIndex++) {
      const sheet = sheetsData[sheetIndex];
      if (mode == 0) {
        sheet.celldata.forEach((cell) => {
          const {r, c, v} = cell
          if (v.field != null && v.m != null) {
            const arr = /\{\&(.+?)\}/g.exec(v.m)
            const m = arr ? arr[1] : v.m
            setCellValue(
                r,
                c,
                { ...v, m, v: m },
                { ...setCellValueOptions }
            )
          }
        })
      } else {
        // 明细 details
        const eachData = eachProps.reduce((res, key) => {
          res[key] = [];
          return res;
        }, {});
        const resEachData = sheet.celldata.reduce((res, cell) => {
          if (cell.v.field) {
            const groupType = cell.v.field.groupType;
            if (eachProps.includes(groupType)) {
              res[groupType].push(cell);
            } else {
              cellDataFormat(cell, data)
            }
          } else {
            cellDataFormat(cell, data)
          }
          return res
        }, eachData);
        // 需遍历的明细数据设置
        let resEachDataSort = []
        for (const key in resEachData) {
          const eachDataItem = resEachData[key] || []
          const sortData = $.extend(true, [], eachDataItem).sort((a, b) => {
            return a.r - b.r
          });
          if (sortData.length) {
            const oneSort = sortData[0];
            const endSort = sortData[sortData.length - 1];
            const columnMax = (sheet.visibledatacolumn.length - 1) || endSort.c;
            resEachDataSort.push({ key, sort: endSort.r, range: {row: [oneSort.r, endSort.r], column: [0, columnMax]} });
            resEachDataSort = $.extend(true, [], resEachDataSort).sort((a, b) => b.sort - a.sort);
          }
        }
        for (let i = 0; i < resEachDataSort.length; i++) {
          const { key, range } = resEachDataSort[i];
          const eachDataItem = resEachData[key] || [];
          const curData = data[key];
          if (eachDataItem.length && curData && curData.length) {
            for (let index = 0; index < curData.length; index++) {
              const rowData = curData[index];
              if (!index) {
                eachDataItem.forEach((cell) => {
                  cellDataFormat(cell, { ...data, ...rowData })
                })
              } else {
                setCopyRangeValue(range, { index, detail: { ...data, ...rowData }, isRefresh: true });
              }
            }
          } else {
            eachDataItem.forEach((cell) => {
              cellDataFormat(cell, { ...data })
            })
          }
          await new Promise((resolve) => {
            const timer = setTimeout(() => {
              clearTimeout(timer);
              resolve(true);
            }, 200);
          });
        }
      }
    }
  },
  async printBefore() {
    const arrData = [];
    const findItem = Store.luckysheetfile.find(item => item.index == Store.currentSheetIndex);
    const findImages = findItem['images'];
    let objData;
    if (Store.luckysheet_select_save.length === 0 || this.selectArea === "0") {
      const columnNum = Store.flowdata[0].length - 1;
      const rowNum = Store.flowdata.length - 1;
      objData = {
        row: [0, rowNum],
        column: [0, columnNum],
        left_move: 0,
        top_move: 0,
        width_move: findItem.ch_width,
        height_move: findItem.rh_height
      };
    } else {
      objData = Store.luckysheet_select_save.length === 1 ? Store.luckysheet_select_save[0] : Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];
    }
    this.saveRange = objData;
    for (const key in findImages) {
      const imgData = findImages[key];
      const newImg = new Image();
      newImg.src = imgData.src;
      newImg.width = imgData.originWidth;
      newImg.height = imgData.originHeight;
      const canvas = document.createElement('canvas');
      canvas.width = imgData.default.width;
      canvas.height = imgData.default.height;
      const canvasCtx = canvas.getContext("2d");
      arrData.push(await new Promise((resolve) => {
        newImg.onload = () => {
          canvasCtx.drawImage(newImg, 0, 0, newImg.width, newImg.height, 0, 0, canvas.width, canvas.height);
          resolve({
            key: key,
            canvas: canvas
          });
        };
      }));
    }
    this.canvasList = arrData;
    // return arrData;
  },
  async handleImage(current, data) {
    const findItem = Store.luckysheetfile.find(item => item.index === Store.currentSheetIndex),
        currentImg = findItem.images[data.key],
        newObj = {
          top: currentImg.default.top,
          bottom: currentImg.default.top + currentImg.default.height,
          left: currentImg.default.left,
          right: currentImg.default.left + currentImg.default.width
        },
        rangeObj = {
          top: this.saveRange.top_move,
          bottom: this.saveRange.top_move + this.saveRange.height_move,
          left: this.saveRange.left_move,
          right: this.saveRange.left_move + this.saveRange.width_move
        };
    if (newObj.bottom < rangeObj.top || newObj.right < rangeObj.left || newObj.left > rangeObj.right || newObj.top > rangeObj.bottom) return;
    const left = Math.max(newObj.left, rangeObj.left);
    const top = Math.max(newObj.top, rangeObj.top);
    const right = Math.min(newObj.right, rangeObj.right);
    const bottom = Math.min(newObj.bottom, rangeObj.bottom);
    current.drawImage(
      data.canvas,
      left - newObj.left,
      top - newObj.top,
      data.canvas.width - left + newObj.left,
      data.canvas.height - top + newObj.top,
      left - rangeObj.left, top - rangeObj.top,
      data.canvas.width - left + newObj.left,
      data.canvas.height - top + newObj.top
    );
  },
  async handleChart(currentData) {
    const findItem = Store.luckysheetfile.find(item => item.index === Store.currentSheetIndex),
        findChart = findItem.chart ?? [];
    if (!findChart.length) return;
    const positionData = {
      top: this.saveRange.top_move,
      bottom: this.saveRange.top_move + this.saveRange.height_move,
      left: this.saveRange.left_move,
      right: this.saveRange.left_move + this.saveRange.width_move
    };
    for (let i = 0; i < findChart.length; i++) {
      const currentChart = findChart[i],
      currentPosition = {
        top: currentChart.top,
        bottom: currentChart.top + currentChart.height,
        left: currentChart.left,
        right: currentChart.left + currentChart.width
      };
      if (currentPosition.bottom < positionData.top || currentPosition.right < positionData.left || currentPosition.left > positionData.right || currentPosition.top > positionData.bottom) continue;
      const chartCanvas = document.getElementById(currentChart.chart_id).querySelector('canvas');
      if (!chartCanvas) continue;
      const left = Math.max(currentPosition.left, positionData.left);
      const top = Math.max(currentPosition.top, positionData.top);
      const right = Math.min(currentPosition.right, positionData.right);
      const bottom = Math.min(currentPosition.bottom, positionData.bottom);
      currentData.drawImage(
        chartCanvas,
        left - currentPosition.left,
        top - currentPosition.top,
        chartCanvas.width - left + currentPosition.left,
        chartCanvas.height - top + currentPosition.top,
        left - positionData.left,
        top - positionData.top,
        chartCanvas.width - left + currentPosition.left,
        chartCanvas.height - top + currentPosition.top
      );
    }
  },
  async drawCanvas(ch_width, ch_height, scrollWidth, scrollHeight, options) {
    Store.devicePixelRatio = Store.devicePixelRatio < 2 ? options.scale : Store.devicePixelRatio;
    const _this = this;
    const newCanvas = $('<canvas>')['attr']({
      width: Math['ceil'](ch_width * Store.devicePixelRatio),
      height: Math['ceil'](ch_height * Store.devicePixelRatio)
    })['css']({
      width: ch_width,
      height: ch_height
    });
    const newCanvasOne = newCanvas.get(0);
    const ctx_newCanvas = newCanvasOne.getContext("2d");
    hideGridLines();
    luckysheetDrawMain(scrollWidth, scrollHeight, ch_width, ch_height, 1, 1, null, null, newCanvas, true);
    showGridLines();
    newCanvasOne.id = 'luckysheet-print-canvas';
    for (let i = 0; i < _this.canvasList.length; i++) {
      const item = _this.canvasList[i];
      await _this.handleImage(ctx_newCanvas, item);
    };
    await _this.handleChart(ctx_newCanvas);
    await _this.breakPage(newCanvasOne, options);
  },
  // 生成并绘制需打印的canvas内容
  async createCanvasDraw(width, height, scale, cloneCanvas, columnWidth, rowHeight, isPrint = false) {
    const createCanvas = document.createElement('canvas');
    createCanvas.width = width * scale;
    createCanvas.height = height * scale;
    createCanvas.style.width = width + 'px';
    createCanvas.style.height = height + 'px';
    const createCanvasContext = createCanvas.getContext("2d");
    if (isPrint) {
      // 设置背景并打印
      const backGroundImages = Store.luckysheetfile[getSheetIndex(Store.currentSheetIndex)].backGroundImages;
      if (backGroundImages && backGroundImages.src && backGroundImages.print) {
        await new Promise(resolve => {
          const bgImg = new Image();
          bgImg.src = backGroundImages.src;
          let sx;
          let sy;
          let sw;
          let sh;
          let imgRatio;
          const canvasRatio = createCanvas.width / createCanvas.height;
          bgImg.onload = () => {
            // 以下代码实现cover效果
            imgRatio = bgImg.width / bgImg.height;
            if(imgRatio <= canvasRatio){
              sw = bgImg.width
              sh = sw / canvasRatio
              sx = 0
              sy = (bgImg.height - sh) / 2
            }else{
              sh = bgImg.height
              sw = sh * canvasRatio
              sx = (bgImg.width - sw) / 2
              sy = 0
            }
            createCanvasContext.drawImage(bgImg,  sx, sy, sw, sh, 0, 0, createCanvas.width, createCanvas.height);
            resolve(true);
          }
        })
      }
    }
    createCanvasContext.scale(scale, scale);
    createCanvasContext.drawImage(cloneCanvas, (columnWidth * Store.devicePixelRatio + 1), rowHeight * Store.devicePixelRatio, width * Store.devicePixelRatio, height * Store.devicePixelRatio, 0, 0, width, height);
    return createCanvas
  },
  //判断canvas是否绘制签名
  isCanvasBlank(canvas) {
    //PNG 算法
    const blank = document.createElement('canvas');
    blank.width = canvas.width;
    blank.height = canvas.height;
    return canvas.toDataURL() == blank.toDataURL();
  },
  // 分页公共方法
  commonBreakPage() {
    const _this = this;
    const pageData = [];
    this.initPrintConfig();
    const { size, direction } = Store.luckysheet_print_config;
    const pixelData = 96,
    // const pixelData = Store.devicePixelRatio * 96,
        sizeData = _this.sizeList[size];
        const tpWidth = Math.floor((sizeData[direction === "0" ? 1 : 0] - _this.padding[1] - _this.padding[3]) * pixelData / 2.541),
            tpHeight = Math.floor((sizeData[direction === "0" ? 0 : 1] - _this.padding[0] - _this.padding[2]) * pixelData / 2.541);
    let visibledatacolumn, visibledatarow;
    if (_this.selectArea == "0") {
      visibledatacolumn = [0, ...Store.visibledatacolumn];
      visibledatarow = [0, ...Store.visibledatarow];
    } else {
      const columnMin = _this.saveRange.column[0];
      const columnMax = _this.saveRange.column[1] + 1;
      visibledatacolumn = [0];
      const dataColumn = columnMin > 0 ? Store.visibledatacolumn[columnMin - 1] : 0;
      for (let i = columnMin; i < columnMax; i++) {
        visibledatacolumn.push(Store.visibledatacolumn[i] - dataColumn);
      }
      const rowMin = _this.saveRange.row[0];
      const rowMax = _this.saveRange.row[1] + 1;
      visibledatarow = [0];
      const dataRow = rowMin > 0 ? Store.visibledatarow[rowMin - 1] : 0;
      for (let i = rowMin; i < rowMax; i++) {
        visibledatarow.push(Store.visibledatarow[i] - dataRow);
      }
    }
    const newDataColumn = this.findValue(0, visibledatacolumn.length - 1, tpWidth, visibledatacolumn);
    // newDataColumn.unshift(0);
    const columnFindIndex = visibledatacolumn.findIndex(item => item === newDataColumn[newDataColumn.length - 1]);
    columnFindIndex !== visibledatacolumn.length - 1 && newDataColumn.push(visibledatacolumn[visibledatacolumn.length - 1]);
    const newDataRow = this.findValue(0, visibledatarow.length - 1, tpHeight, visibledatarow);
    // newDataRow.unshift(0);
    const rowFindIndex = visibledatarow.findIndex(item => item === newDataRow[newDataRow.length - 1]);
    rowFindIndex !== visibledatarow.length - 1 && newDataRow.push(visibledatarow[visibledatarow.length - 1]);
    for (let rowKey = 0; rowKey < newDataRow.length; rowKey++) {
      if (rowKey == 0) continue;
      const height = newDataRow[rowKey] - newDataRow[rowKey - 1];
      for (let columnKey = 0; columnKey < newDataColumn.length; columnKey++) {
        if (columnKey == 0) continue;
        const width = newDataColumn[columnKey] - newDataColumn[columnKey - 1];
        pageData.push({
          tpWidth,
          tpHeight,
          width,
          height,
          columnWidth: newDataColumn[columnKey - 1],
          rowHeight: newDataRow[rowKey - 1]
        })
      }
    }
    return pageData
  },
  // sheet分页处理
  sheetBreakPage(luckysheetTableContent, isPrint) {
    const _this = this;
    const color = '#2787ff';
    const headerHeight = Store.columnHeaderHeight;
    const indexWidth = Store.rowHeaderWidth;
    const scrollLeft = $("#luckysheet-scrollbar-x")[0].scrollLeft;
    const scrollTop = $("#luckysheet-scrollbar-y")[0].scrollTop;
    const pageData = _this.commonBreakPage();
    pageData.forEach(({width: ct_width, height: ct_height, columnWidth, rowHeight}) => {
      const width = (ct_width + (!isPrint ? indexWidth : 0) + columnWidth - scrollLeft) * Store.devicePixelRatio;
      const height = (ct_height + (!isPrint ? headerHeight : 0) + rowHeight - scrollTop) * Store.devicePixelRatio;
      if (!isPrint) {
        const backGroundImages = Store.luckysheetfile[getSheetIndex(Store.currentSheetIndex)].backGroundImages;
        if (!columnWidth && !rowHeight && backGroundImages && backGroundImages.src) {
          const sheettheightssDom = $("#luckysheet-sheettheightss");
          sheettheightssDom.remove();
          $("#luckysheetcoltable_0").prepend('<div id="luckysheet-sheettheightss" style="width:' + ct_width + "px;height:" + ct_height + 'px;position: absolute;"></div>');
          sheettheightssDom.css("z-index", "1");
          sheettheightssDom.css("background-image", "url(" + backGroundImages.src + ")");
          sheettheightssDom.css("background-size", "cover");
        }
        this.drawLine(luckysheetTableContent, width, 0, width, height, color);
        this.drawLine(luckysheetTableContent, 0, height, width, height, color);
      } else {
        luckysheetTableContent.clearRect(width, 0, 2, height);
        luckysheetTableContent.clearRect(0, height, width, 2);
      }
    })
  },
  // 打印分页
  async breakPage(curData, options) {
    const _this = this;
    const { pdfName, timeout, autoPreview = true, onOpenLink, scale } = options || {};
    _this.initPrintConfig();
    const { size, direction } = Store.luckysheet_print_config;
    const pdfDirection = direction === '0' ? 'l' : 'p';
    let sizeData = _this.sizeList[size].map(val => val * 10);
    if (pdfDirection === 'l') sizeData = sizeData.reverse();
    if (_this.jsPdf == null) {
      _this.jsPdf = new JsPDF(pdfDirection, 'mm', sizeData, true);
    }
    const PDF = _this.jsPdf;
    const pageData = _this.commonBreakPage();
    for (let index = 0; index < pageData.length; index++) {
      const { tpWidth, width, height, columnWidth, rowHeight } = pageData[index];
      const createCanvas = await _this.createCanvasDraw(width, height, scale, curData, columnWidth, rowHeight, true);
      const createCanvasBlank = await _this.createCanvasDraw(width, height, scale, curData, columnWidth, rowHeight);

      // 判断当前区域有无打印内容
      if (!_this.isCanvasBlank(createCanvasBlank)) {
        const imageData = createCanvas.toDataURL('image/png', 1);
        const [top, right, bottom, left] = _this.padding.map(val => val * 10);
        const contentWidth = createCanvas.width;
        const pdfWidth = sizeData[0] - right - left;
        const pdfHeight = sizeData[1] - top - bottom;
        const imgWidth = pdfWidth * contentWidth / tpWidth;
        const imgHeight = pdfHeight * imgWidth / pdfWidth;
        if (index) {
          await PDF.addPage(sizeData, pdfDirection);
        }
        await PDF.addImage(imageData, 'png', left, top, imgWidth / scale, imgHeight / scale);
        // 避免pdf没有生成完成
        await new Promise((resolve) => {
          const timer = setTimeout(() => {
            clearTimeout(timer);
            resolve(true);
          }, 200);
        });
      }
    }
    if (autoPreview) {
      setTimeout(() => {
        Store.devicePixelRatio = window.devicePixelRatio;
        const filename = pdfName || '打印预览';
        PDF.setProperties({title: filename});
        if (onOpenLink && typeof onOpenLink === 'function') {
          onOpenLink(PDF);
        } else {
          const pdfUrl = PDF.output('bloburl');
          window.open(pdfUrl, '_blank');
        }
        _this.jsPdf = null;
        _this.onafterprint(timeout);
      }, 1500)
    } else {
      await PDF.addPage(sizeData, pdfDirection);
    }
  },
  findValue(index, length, curLength, data) {
    return data.reduce((res, curData, curIndex) => {
      if (curData - res[res.length - 1] > curLength) {
        res.push(data[curIndex - 1])
      }
      return res
    }, [0])
  },
  drawLine(canvans, arrowX, arrowY, startX, startY, color) {
    canvans.beginPath(),
    canvans.setLineDash([5]),
    canvans.moveTo(arrowX, arrowY),
    canvans.lineTo(startX, startY),
    canvans.lineWidth = Store.devicePixelRatio * 2,
    canvans.strokeStyle = color || luckysheetdefaultstyle.strokeStyle,
    canvans.stroke(),
    canvans.setLineDash([]),
    canvans.closePath();
  }
};
